<template>
 <TableStyleWrapper>
  <TableWrapper class="table-responsive">
   <a-table
    :columns="exportTableColumns"
    :dataSource="exportTableData"
    :pagination="{
          defaultPageSize: pageSize,
          total: count,
          current: currentPage,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${count} items`,
        }"
    @change="paginationChange"
   />
  </TableWrapper>
 </TableStyleWrapper>
</template>
<script>
import {TableStyleWrapper} from '../../user/style';
import {TableWrapper} from '../../styled';
import {computed, defineComponent} from 'vue';
import {useStore} from "vuex";
import moment from "moment";
import {useAbility} from "@casl/vue";

const exportTableColumns = [
 {
  title: 'ID',
  dataIndex: 'id',
  key: 'id',
 },
 {
  title: 'Type',
  dataIndex: 'type',
  key: 'type',
 },
 {
  title: 'Status',
  dataIndex: 'status',
  key: 'status',
 },
 {
  title: 'Error',
  dataIndex: 'failReason',
  key: 'failReason',
 },
 {
  title: 'Aangemaakt op',
  dataIndex: 'createTimestamp',
  key: 'createTimestamp',
 },
 {
  title: 'Actions',
  dataIndex: 'action',
  key: 'action',
  width: '90px',
 },
];
const ExportListTable = defineComponent({
 name: 'ExportListTable',
 components: {TableStyleWrapper, TableWrapper},
 setup() {
  const {state, dispatch} = useStore();
  const exports = computed(() => state.exports.exports);
  const count = computed(() => state.exports.count);
  const pageSize = computed(() => state.exports.pageSize);
  const currentPage = computed(() => state.exports.currentPage);
  const {can} = useAbility();
  // const handleDelete = (id) => {
  //   dispatch('deleteExport', id);
  // };
  // const handleEdit = (id) => {
  //   router.push(`${path}/${id}`);
  // };
  const resolveStatusVariant = status => {
   if (status === 'NEW') return {variant: 'pending', label: 'Nieuw'};
   if (status === 'PROCESSING') return {variant: 'pending', label: 'In behandeling'};
   if (status === 'FAILED') return {variant: 'inactive', label: 'Error'};
   if (status === 'FINISHED') return {variant: 'active', label: 'Afgerond'};
   if (status === 'EXPIRED') return {variant: 'inactive', label: 'Afgerond'};
   return {variant: '', label: 'Status onbekend'};
  }

  const resolveTypeVariant = type => {
   if (type === 'ENERGYORDERS') return 'Orders Energie per EAN';
    if (type === 'ENERGYORDERSSINGLE') return 'Orders Energie';
   if (type === 'ENERGYORDERSGREENCHOICE') return 'Orders Greenchoice';
   if (type === 'ENERGYORDERSPSD') return 'CRM';
   if (type === 'CONTRACTFILES') return 'Contracten (bestanden)';
   if (type === 'GENERALORDERS') return 'Algemene order export';

   return 'Type onbekend';
  }
  const exportTableData = computed(() =>
   exports.value.map((item) => {
    const {id, createTimestamp, status, type, failReason, fileURL} = item;

    return {
     key: id,
     id: id,
     type: <span>{resolveTypeVariant(type)}</span>,
     createTimestamp: <span>{moment(createTimestamp).format('DD-MM-YYYY HH:mm:ss')}</span>,
     status: <span
      className={`status-text ${resolveStatusVariant(status).variant}`}>{resolveStatusVariant(status).label}</span>,
     failReason: (status === 'FAILED' ? (failReason) : ('')),
     action: (
      <div className="table-actions">
       {can('index', 'export') && status === 'FINISHED' ? (
        <a href={fileURL} target="_blank">
         <sdFeatherIcons type="download" size="16"/>
        </a>
       ) : ('')}
       {status === 'PROCESSING' ? (
        <a-spin size="small"/>
       ) : ('')}
      </div>
     ),
    };
   }),
  );

  const rowSelection = {
   getCheckboxProps: (record) => ({
    disabled: record.name === 'Disabled export', // Column configuration not to be checked
    name: record.name,
   }),
  };

  const paginationChange = async (event) => {
   await dispatch('setCurrentPageExport', event.current);
   await dispatch('getExports', {page: event.current, pageSize: state.user.pageSize});
  }

  return {
   pageSize,
   count,
   exportTableColumns,
   exportTableData,
   rowSelection,
   resolveStatusVariant,
   resolveTypeVariant,
   paginationChange,
   currentPage,
  };
 },
});

export default ExportListTable;
</script>
