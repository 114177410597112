<template>
 <sdDrawer
  ref="ExportAddRef"
  :form="form"
  btnText="Toevoegen"
  submitBtnText="Aanmaken"
  title="Export aanmaken"
  type="submit"
 >
  <FormValidationWrap>
   <a-form ref="formRef" :model="form" layout="vertical" @finish="CreateExport">
    <a-row :gutter="16">
     <a-col :md="12" :xs="24">
      <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Type"
                   name="type">
       <a-select v-model:value="form.type"
                 :allowClear="true" class="sDash_fullwidth-select"
                 size="large">
        <a-select-option v-for="(item,index) in ExportTypeOptions" :key="index"
                         :value="item.value" name="type">
         {{ item.key }}
        </a-select-option>
       </a-select>
      </a-form-item>
     </a-col>
     <a-col :md="12" :xs="24">
      <a-form-item label="Organisatie" name="organizationID" style="margin-bottom: 5px">
       <a-select v-model:value="form.params.organizationID" :allowClear="true" :filter-option="false"
                 :showSearch="false" class="sDash_fullwidth-select" maxTagCount="responsive" mode="multiple"
                 size="large">
        <a-select-option v-for="(item,index) in orderOrganization" :key="index" :value="item.key"
                         name="organizationID">
         {{ item.value }}
        </a-select-option>
       </a-select>
      </a-form-item>
     </a-col>
     <a-col :md="12" :xs="24" class="mb-25">
      <a-form-item label="Gebruiker" name="userID" style="margin-bottom: 5px">
       <a-select v-model:value="form.params.userID" :allowClear="true" :filter-option="false" :showSearch="true"
                 class="sDash_fullwidth-select"
                 maxTagCount="responsive" mode="multiple" placeholder="Zoeken..." size="large"
                 @search="fetchUserFilters">
        <a-select-option v-for="(item,index) in userFilters" :key="index" :value="item.key"
                         name="userID">
         {{ item.value }}
        </a-select-option>
       </a-select>
      </a-form-item>
     </a-col>
     <a-col :md="12" :xs="24">
      <a-form-item label="Status">
       <a-tree-select
        v-model:value="form.params.status"
        :show-checked-strategy="SHOW_PARENT"
        :style="{ width: '100%' }"
        :tree-data="statusFilters"
        allow-clear
        class="ant-tree-select"
        maxTagCount="responsive"
        search-placeholder="Please select"
        style="min-height: 48px;"
        tree-checkable
       />
      </a-form-item>
     </a-col>
     <a-col :md="12" :xs="24">
      <a-form-item label="Product">
       <a-tree-select
        v-model:value="form.params.product"
        :show-checked-strategy="SHOW_PARENT"
        :style="{ width: '100%' }"
        :tree-data="productFilters"
        allow-clear
        class="ant-tree-select"
        search-placeholder="Maak een keuze"
        tree-checkable
       />
      </a-form-item>
     </a-col>
     <a-col :md="12" :xs="24">
      <a-form-item label="Team" name="teamID" style="margin-bottom: 10px">
       <a-select v-model:value="form.params.teamID" :allowClear="true" :filter-option="false" :showSearch="false"
                 class="sDash_fullwidth-select" maxTagCount="responsive" mode="multiple" name="roleID" size="large">
        <a-select-option v-for="(item,index) in teamFilters" :key="index" :value="item.key">
         {{ item.value }}
        </a-select-option>
       </a-select>
      </a-form-item>
     </a-col>
     <a-col :md="12" :xs="24">
      <a-form-item label="Klanttype" name="productCustomerType" style="margin-bottom: 10px">
       <a-select v-model:value="form.params.productCustomerType" :allowClear="true" :filter-option="false"
                 :showSearch="false"
                 class="sDash_fullwidth-select" maxTagCount="responsive" mode="multiple" name="roleID" size="large">
        <a-select-option v-for="(item,index) in productCustomerType" :key="index" :value="item.value">
         {{ item.key }}
        </a-select-option>
       </a-select>
      </a-form-item>
     </a-col>
     <a-col :md="12" :xs="24">
      <a-form-item label="Geannuleerd" name="isCancelled" style="margin-bottom: 10px">
       <a-select v-model:value="form.params.isCancelled" :allowClear="true" class="sDash_fullwidth-select"
                 maxTagCount="responsive"
                 name="roleID" size="large">
        <a-select-option v-for="(item,index) in defaultOptions" :key="index" :value="item.value">
         {{ item.key }}
        </a-select-option>
       </a-select>
      </a-form-item>
     </a-col>
     <a-col :md="12" :xs="24">
      <a-form-item label="Ondertekend" name="isSigned" style="margin-bottom: 10px">
       <a-select v-model:value="form.params.isSigned" :allowClear="true" class="sDash_fullwidth-select" name="roleID"
                 size="large">
        <a-select-option v-for="(item,index) in defaultOptions" :key="index" :value="item.value">
         {{ item.key }}
        </a-select-option>
       </a-select>
      </a-form-item>
     </a-col>
     <a-col :md="12" :xs="24">
      <a-form-item label="Verzonden" name="isSent" style="margin-bottom: 10px">
       <a-select v-model:value="form.params.isSent" :allowClear="true" class="sDash_fullwidth-select" name="roleID"
                 size="large">
        <a-select-option v-for="(item,index) in defaultOptions" :key="index" :value="item.value">
         {{ item.key }}
        </a-select-option>
       </a-select>
      </a-form-item>
     </a-col>
     <a-col :md="12" :xs="24">
      <a-form-item label="Datum filteren op" name="dateFilterType" style="margin-bottom: 10px">
       <a-select v-model:value="form.params.dateFilterType" :allowClear="true" class="sDash_fullwidth-select"
                 name="roleID"
                 size="large">
        <a-select-option v-for="(item,index) in dateFilterType" :key="index" :value="item.value">
         {{ item.key }}
        </a-select-option>
       </a-select>
      </a-form-item>
     </a-col>
     <a-col :sm="12" :xs="24">
      <a-form-item :name="['params', 'dateFrom']" :rules="{required: true,message: 'Dit veld is verplicht'}"
                   label="Datum van">
       <DatePickerWrapper>
        <a-date-picker v-model:value="form.params.dateFrom" :format="dateFormat" class="w-100"/>
       </DatePickerWrapper>
      </a-form-item>
     </a-col>
     <a-col :sm="12" :xs="24">
      <a-form-item :name="['params', 'dateTill']" :rules="{required: true,message: 'Dit veld is verplicht'}"
                   label="Datum t/m">
       <DatePickerWrapper>
        <a-date-picker v-model:value="form.params.dateTill" :format="dateFormat" class="w-100"/>
       </DatePickerWrapper>
      </a-form-item>
     </a-col>
    </a-row>
    <div
     :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
    >
     <a-button :disabled="loadingCreateExport" :loading="loadingCreateExport" class="btn-signin" html-type="submit"
               size="large"
               type="primary"> Aanmaken
     </a-button>
    </div>
   </a-form>
  </FormValidationWrap>
 </sdDrawer>
</template>
<script>
import {computed, defineComponent, onMounted, reactive, ref} from 'vue';
import {FormValidationWrap} from "../styled";
import {useStore} from "vuex";
import {dateFilterType, defaultOptions, ExportTypeOptions, productCustomerType} from "../../utility/enums";
import {TreeSelect} from "ant-design-vue";

const ExportAdd = defineComponent({
 name: 'ExportAdd',
 components: {
  FormValidationWrap,
 },
 data() {
  return {
   dateFormat: 'DD-MM-YYYY',
   loading: false,
   SHOW_PARENT: TreeSelect.SHOW_PARENT,
  }
 },
 setup() {
  const {state, dispatch} = useStore();
  const orderOrganization = computed(() => state.filter.orderOrganization);
  const statusFilters = computed(() => state.filter.orderStatus);
  const teamFilters = computed(() => state.filter.orderTeams);
  const productFilters = computed(() => state.filter.productFilters);
  const loadingCreateExport = computed(() => state.exports.loadingCreateExport);
  const userFilters = computed(() => state.filter.userFilters);
  const ExportAddRef = ref();
  const formRef = ref();
  let debounceUserFilter = null;
  const form = reactive({
   type: null,
   params: {
    dateFrom: '',
    dateTill: '',
    organizationID: [],
    status: [],
    teamID: [],
    productCustomerType: [],
    product: [],
    userID: [],
    isCancelled: null,
    isSigned: null,
    isSent: null,
    dateFilterType: 'CREATETIMESTAMP',
   },
  });

  const closeDrawer = () => {
   ExportAddRef.value.onClose();
   form.type = null;
   form.params.dateFrom = '';
   form.params.dateTill = '';
   form.params.organizationID = [];
   form.params.product = [];
   form.params.status = [];
   form.params.teamID = [];
   form.params.userID = [];
   form.params.productCustomerType = [];
   form.params.isCancelled = null;
   form.params.isSigned = null;
   form.params.isSent = null;
   form.params.dateFilterType = 'CREATETIMESTAMP';
   dispatch('getExports', {page: state.exports.currentPage, pageSize: state.exports.pageSize});
  }

  const CreateExport = () => {
   const data = JSON.parse(JSON.stringify(form));
   data.params.organizationID = data.params.organizationID ? data.params.organizationID.join(',') : null;
   data.params.product = data.params.product ? data.params.product.join(',') : null;
   data.params.status = data.params.status ? data.params.status.join(',') : null;
   data.params.teamID = data.params.teamID ? data.params.teamID.join(',') : null;
   data.params.userID = data.params.userID ? data.params.userID.join(',') : null;
   data.params.productCustomerType = data.params.productCustomerType ? data.params.productCustomerType.join(',') : null;
   dispatch('createExport', {value: data, close: closeDrawer});
  };

  const fetchUserFilters = (search) => {
   clearTimeout(debounceUserFilter)
   debounceUserFilter = setTimeout(() => {
    dispatch('getUserFilters', {q: search, pageSize: 20});
   }, 300);
  };

  onMounted(() => {
   dispatch('getOrderOrganizationFilters');
   dispatch('getOrderTeamFilters', null);
   dispatch('getOrderProductFilters');
   dispatch('getOrderStatusFilters');
   dispatch('getUserFilters', {q: 'a', pageSize: 20});
  });

  return {
   form,
   CreateExport,
   ExportTypeOptions,
   loadingCreateExport,
   ExportAddRef,
   formRef,
   orderOrganization,
   statusFilters,
   teamFilters,
   defaultOptions,
   dateFilterType,
   productCustomerType,
   productFilters,
   userFilters,
   fetchUserFilters,
  };
 },
});

export default ExportAdd;
</script>
