<template>
 <CardToolbox>
  <sdPageHeader title="Export">
   <template #subTitle>
    <span class="title-counter">{{ count }} export{{ count > 0 && count < 2 ? '' : 's' }}</span>
   </template>
   <template #buttons>
    <ExportAdd v-if="$can('create', 'export')"/>
   </template>
  </sdPageHeader>
 </CardToolbox>
 <Main>
  <a-row :gutter="15">
   <a-col :md="24">
    <ExportTable/>
   </a-col>
  </a-row>
 </Main>
</template>
<script>
import ExportTable from './component/ExportTable';
import {computed, defineComponent, onMounted, ref} from 'vue';
import {useStore} from 'vuex';
import {CardToolbox, Main} from '../styled';
import ExportAdd from "./ExportAdd";

const ExportList = defineComponent({
 name: 'ExportList',
 components: {ExportAdd, Main, CardToolbox, ExportTable},
 data() {
  return {
   showEditDrawer: false,
  }
 },
 beforeUnmount() {
  this.beforeDestroy();
 },
 setup() {
  const {state, dispatch} = useStore();
  const searchData = computed(() => state.headerSearchData);
  const count = computed(() => state.exports.count);
  const selectedRowKeys = ref(0);
  const selectedRows = ref(0);
  let interval = ref(null)

  onMounted(() => {
   dispatch('getExports', {page: state.exports.currentPage, pageSize: state.exports.pageSize});
   interval = setTimeout(() => {
    dispatch('getExports', {page: state.exports.currentPage, pageSize: state.exports.pageSize});
   }, 5000)
  });

  const beforeDestroy = () => {
   clearTimeout(interval)
  };

  return {
   searchData,
   selectedRowKeys,
   selectedRows,
   count,
   beforeDestroy,
  };
 },
});

export default ExportList;
</script>
